<template>
  <div class="page-container">
    <!--筛选-->
    <div class="filter-container-ori flex flex-between">
      <div class="search-box">
        <el-input v-model="lists.searchFilter.search[0].value" :placeholder="lists.searchFilter.search[0].label"
                  style="width: 300px">
          <el-button slot="append" icon="el-icon-search" @click="ListMethods().clickSearchFilterBtn()"></el-button>
        </el-input>
      </div>
      <div class="btn-box">
        <el-button type="primary" size="small" @click="ListMethods().clickAddEntityBtn()">添加教学班</el-button>
        <el-button type="success" size="small" @click="ListMethods().clickEditAdministrationClazzBtn()" v-if="false">
          管理行政班学生
        </el-button>
      </div>
    </div>
    <!--列表-->
    <div class="table-container">
      <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="lists.list" v-loading="lists.loading"
                element-loading-text="加载中" fit
                style="width: 100%;" @sort-change="sort=>ListMethods().sortChange(sort)">
        <el-table-column label="教学班" prop="clazzName" align="center" :sortable="'custom'" min-width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.clazzName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="行政班" align="center" prop="administrationClazzNames" :sortable="'custom'" width="400px">
          <template slot-scope="scope">
            <span>{{ scope.row.administrationClazzNames.replace(",", "、") }}</span>
          </template>
        </el-table-column>
        <el-table-column label="学生人数" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.studentCount }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250"
                         class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="text" size="mini" round
                       @click="ListMethods().clickEditBtn(scope.row,scope.$index)">修改
            </el-button>
            <el-button type="text" size="mini" round
                       @click="ListMethods().clickViewBtn(scope.row,scope.$index)">学生列表
            </el-button>
            <el-button type="text" size="mini" round
                       @click="e=>ListMethods().clickDeleteBtn(scope.row,scope.$index)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--列表分页-->
    <div class="pagination-container">
      <el-pagination background @current-change="(number)=>ListMethods().pageChange(number)"
                     :current-page.sync="lists.pages.number" :page-size.sync="lists.pages.size"
                     layout="total,prev, pager, next,sizes,jumper" :total="lists.pages.totalElements"
                     @size-change="(size)=>ListMethods().pageLimitChange(size)"
                     :page-count="lists.pages.totalPages">
      </el-pagination>
    </div>
    <!--详情弹窗-班级详情-->
    <el-dialog
        :close-on-click-modal="false"
        :title="entityInfo.title"
        :visible.sync="entityInfo.dialog"
        width="700px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form label-width="120px" ref="entityInfoForm" :model="entityInfo.edit" :rules="entityInfo.formRules">
          <el-form-item label="教学班名称" prop="clazzName">
            <el-input style="width: 500px" v-model.trim="entityInfo.edit.clazzName" placeholder="请输入教学班名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="行政班级" prop="administrationClazzIdsShow">
            <el-select
                style="width: 500px"
                v-model="entityInfo.edit.administrationClazzIdsShow"
                multiple
                filterable
                remote
                reserve-keyword
                placeholder="请输入行政班名称进行搜索"
                :remote-method="v=>ListMethods().getAdministrationClazzList(v)"
                :loading="entityInfo.filter[0]['loading']">
              <el-option
                  v-for="item in entityInfo.filter[0]['data']"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="entityInfo.dialog=false">取 消</el-button>
        <el-button type="primary" v-if="entityInfo.type==='add'" :loading="saveOrEditDoing"
                   @click="EntityInfoMethods().clickAddBtn()">新 增</el-button>
         <el-button type="primary" v-if="entityInfo.type==='edit'" :loading="saveOrEditDoing"
                    @click="EntityInfoMethods().clickEditBtn()">修 改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * todo 排序
 * 按原型修改
 */
import ListSearchFilter from '@/components/list/listSearchFilter'
import {EnumsModel} from '@/model/EnumsModel'
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_err, msg_input, msg_success} from '@/utils/ele_component'
import {date_format, find_obj_from_arr_by_id, objectToLVArr, validateMaxLength} from '@/utils/common'
import {mapState} from 'vuex'
import {ClazzModel} from "@/model/exp/ClazzModel";
import {CommonModel} from "@/model/CommonModel";
import {DepartmentModel} from "@/model/exp/DepartmentModel";
import {RouterModel} from "@/model/RouterModel";
import {AdministrationClazzModel} from "@/model/exp/AdministrationClazzModel";
import {AdminUserModel} from "@/model/erp/AdminUserModel";
import {URL_ERP} from "@/model/ConfigModel";

export default {
  name: 'teacherClazz',
  components: {ListSearchFilter},
  directives: {
    elDragDialog
  },
  // 过滤器
  filters: {
    mobile(value) {
      if (value && value.length === 11) {
        let start = value.slice(0, 3)
        let end = value.slice(-4)
        return `${start}****${end}`
      }
      return ""
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      // 外部方法
      date_format: date_format,
      // 枚举
      enums: EnumsModel,
      // 列表
      lists: {
        list: [],
        loading: false,
        query: {},
        queryBase: {
          needStudentNumber: true
        },
        pages: {
          size: 20
        },
        searchFilter: {
          search: [
            {
              type: 'input',
              label: '教学班名称、行政班名称',
              key: 'search',
              value: ''
            },
          ],
          filter: []
        }
      },
      // 实体详情
      entityInfo: {
        title: "新增班级",
        type: "add",
        dialog: false,
        filter: [
          // 行政班列表
          {
            loading: false,
            data: [],
            dataObject: {},
            dataOrigin: [],
          },
        ],
        edit: {
          // 选择的行政班id列表
          administrationClazzIdsShow: []
        },
        firstCheck: false,
        // 输入检测
        formRules: {
          // 'administrationClazzIdsShow': {required: true, message: '请选择行政班级', trigger: 'change'},
          'clazzName': {
            required: true,
            validator: (r, v, c) => validateMaxLength(r, v, c, 30, "班级名称"),
            trigger: 'blur'
          },
        },
      },
      // 保存或新增加载
      saveOrEditDoing: false,
    }
  },
  async mounted() {
    // 获取列表
    this.ListMethods().getList(1, this.lists.pages.size, this.lists.query)
    // 初始化筛选
    this.ListMethods().initFilter()
  },
  methods: {
    // 列表Methods
    ListMethods() {
      let $this = this
      return {
        // 点击管理行政班学生按钮
        async clickEditAdministrationClazzBtn() {
          let teacherId = $this.userInfo.userid
          let schoolId = $this.userInfo.schoolId
          let res = await AdminUserModel.generateErpExpTeacherToken(teacherId)
          if (res.code === "000000") {
            window.open(URL_ERP + `#/login?redirect=%2Fdashboard&expTeacher_schoolId=${schoolId}&expTeacherToken=` + res.data)
          }
        },
        // 搜索行政班列表
        async getAdministrationClazzList(name) {
          let query = {
            schoolId: $this.userInfo.schoolId,
            name: name
          };
          let [list] = await AdministrationClazzModel.getList(1, -1, query)
          let options = [];// [{label:"","value":{}]
          let optionsObject = {};// {"value":"label"}
          list.forEach(li => {
            options.push({
              label: li['gradeName'] + ' - ' + li['name'],
              value: li['id']
            })
            optionsObject[li['id']] = li['gradeName'] + ' ' + li['name'];
          });
          $this.$set($this.entityInfo.filter[0], "data", options)
          $this.$set($this.entityInfo.filter[0], "dataObject", optionsObject)
          $this.$set($this.entityInfo.filter[0], "dataOrigin", list)
        },
        // 获取列表
        async getList(page, size, query) {
          query = Object.assign(query, $this.lists.queryBase)
          $this.lists.loading = true;
          // 如果没有排序 默认班级名称排序
          if (!query.sort) {
            query.sort = "clazzName,asc"
          }
          [$this.lists.list, $this.lists.pages] = await ClazzModel.getList(page, size, query)
          $this.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.lists.pages.size, $this.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.lists.pages.number, size, $this.lists.query)
        },
        // 排序被改变
        async sortChange(sort) {
          if (sort.column.sortable === 'custom') {
            let querySort = $this.lists.query.sort
            querySort = CommonModel.elementTableSort(sort)
            $this.$set($this.lists.query, "sort", querySort)
            this.getList(1, $this.lists.pages.size, $this.lists.query)
          }
        },
        // 初始化筛选列表
        async initFilter(type, setNull) {
          $this.$forceUpdate();
        },
        // 点击搜索按钮
        clickSearchFilterBtn() {
          $this.lists.query.search = $this.lists.searchFilter.search[0].value
          this.getList(1, $this.lists.pages.size, $this.lists.query)
        },
        // 点击删除按钮
        async clickDeleteBtn(entity, index) {
          if (await msg_confirm("确认要删除该班级吗？")) {
            if (await ClazzModel.remove([entity.clazzid])) {
              msg_success("删除成功")
              $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
            } else {
              msg_err("删除失败")
            }
          }
        },
        // 点击新增班级按钮
        clickAddEntityBtn() {
          $this.entityInfo.type = "add"
          $this.entityInfo.title = "添加教学班"
          $this.entityInfo.edit = {
            administrationClazzIdsShow: []
          }
          $this.entityInfo.dialog = true;
          $this.ListMethods().getAdministrationClazzList(null)
          setTimeout(() => {
            $this.$refs["entityInfoForm"].clearValidate()
          }, 300)
        },
        // 点击编辑详情按钮
        clickEditBtn(entity, $index) {
          $this.entityInfo.type = "edit"
          $this.entityInfo.title = "修改教学班"
          $this.entityInfo.edit = JSON.parse(JSON.stringify(entity))
          $this.$set($this.entityInfo.edit, "administrationClazzIdsShow", entity.administrationClazzIds.split("~"));
          $this.entityInfo.$index = $index
          $this.entityInfo.dialog = true;
          $this.ListMethods().getAdministrationClazzList(null)
          setTimeout(() => {
            $this.$refs["entityInfoForm"].clearValidate()
          }, 300)
        },
        // 点击学生列表按钮
        clickViewBtn(entity) {
          RouterModel.routerPush($this, `/teacher/clazzInfo?clazzId=${entity.clazzid}&clazzName=${encodeURI(entity.clazzName)}`, "班级管理 / 班级详情", "", '6', [
            {name: "班级管理", path: "/teacher/clazz", index: '6'},
            {name: "班级详情"}
          ])
        }
      }
    },
    // 实体Methods
    EntityInfoMethods() {
      let $this = this;
      return {
        // 学院被改变
        async onDepartmentChange(v) {
          $this.$set($this.entityInfo.edit, "departmentid", v)
          $this.ListMethods().initFilter(1, true)
          $this.ListMethods().initFilter(2, true)
          $this.$forceUpdate();
        },
        // 点击新增按钮
        async clickAddBtn() {
          $this.entityInfo.firstCheck = true
          $this.$refs['entityInfoForm'].validate(async validate => {
            if (validate) {
              $this.saveOrEditDoing = true
              // 构建参数
              $this.entityInfo.edit.name = $this.entityInfo.edit.clazzName
              $this.entityInfo.edit.schoolId = $this.userInfo.schoolId
              // 生成行政班级ids
              $this.entityInfo.edit.administrationClazzIds = $this.entityInfo.edit.administrationClazzIdsShow.join("~");
              // 生成行政班级names
              let clazzNames = []
              $this.entityInfo.edit.administrationClazzIdsShow.forEach(li => {
                clazzNames.push($this.entityInfo.filter[0]["dataObject"][li])
              })
              $this.entityInfo.edit.administrationClazzNames = clazzNames.join("~");
              if (await ClazzModel.save($this.entityInfo.edit).catch(() => {
                $this.saveOrEditDoing = false
              })) {
                msg_success('新增成功')
                $this.ListMethods().getList(1, $this.lists.pages.size, $this.lists.query)
                $this.entityInfo.dialog = false
              }
              $this.entityInfo.firstCheck = false
              $this.saveOrEditDoing = false
            }
          })
        },
        // 点击修改按钮
        async clickEditBtn() {
          $this.$refs['entityInfoForm'].validate(async validate => {
            if (validate) {
              $this.saveOrEditDoing = true
              // 构建参数
              $this.entityInfo.edit.name = $this.entityInfo.edit.clazzName
              $this.entityInfo.edit.id = $this.entityInfo.edit.clazzid
              $this.entityInfo.edit.schoolId = $this.userInfo.schoolId
              // 生成行政班ids
              $this.entityInfo.edit.administrationClazzIds = $this.entityInfo.edit.administrationClazzIdsShow.join("~");
              // 生成行政班级names
              let clazzNames = []
              $this.entityInfo.edit.administrationClazzIdsShow.forEach(li => {
                clazzNames.push($this.entityInfo.filter[0]["dataObject"][li])
              })
              $this.entityInfo.edit.administrationClazzNames = clazzNames.join("~");
              if (await ClazzModel.update($this.entityInfo.edit).catch(() => {
                $this.saveOrEditDoing = false
              })) {
                msg_success('修改成功')
                // 更新数据
                $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
                $this.entityInfo.dialog = false
                $this.saveOrEditDoing = false
              }
            }
          });
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
.department-title {
  text-align: center;
  margin-bottom: 20px;
}
</style>
