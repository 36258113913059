import {
    generateErpExpTeacherToken
} from "@/api/erp/AdminUserApi";

/**
 *
 */
class AdminUserModel {
    // 直接生成erp平台，实验平台教师用户expTeacher的token
    static async generateErpExpTeacherToken(teacherId) {
        let [data] = await generateErpExpTeacherToken({
            teacherId
        });
        return data;
    }
}

export {AdminUserModel}
